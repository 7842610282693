<template>
    <div>
        
        <!--md-button class="md-raised md-primary" @click="UserProfile()">Update custom claims</md-button>
        <md-button class="md-raised md-primary" @click="claimsFixer()">User claims fixer</md-button-->
        <md-button class="md-raised md-primary" @click="firestoreFixer()">Firestore fixer</md-button>
    </div>
</template>
<script>
import axios from "./../../helpers/axios";

export default {
    name: "Fixer",
    
    computed: {
        // map `this.user` to `this.$store.getters.user`
        user() {
            return this.$store.state.user;
        }
    },
    created() {

        //this.updateClock();
        this.getUserData()
    },
    data() {
        return {
        
        };
    },
    methods: {
        async firestoreFixer() {
            // users-claims-fixer
            let data = {}
            return new Promise((resolve, reject) => {
                axios.post('firestore-fixer',data).then(response =>{
                    console.log(response);
                    
                    resolve(response)
                    //th.$router.push({path: '/users/drivers'})
                }).catch(e => {
                    console.log(e);
                    
                    reject(e)
                    
                }) 
            })
        },
        async claimsFixer() {
            // users-claims-fixer
            let data = {}
            return new Promise((resolve, reject) => {
                axios.post('users-claims-fixer',data).then(response =>{
                    console.log(response);
                    
                    resolve(response)
                    //th.$router.push({path: '/users/drivers'})
                }).catch(e => {
                    console.log(e);
                    
                    reject(e)
                    
                }) 
            })
        },
        async UserProfile() {
            // claimsExample: {
            //     activeRole: 'company-admin',
            //     roles: [
            //             {
            //                 slug: 'company-admin',
            //             },
            //             {
            //                 slug: 'driver',
            //             }
            //         ]
            // }
            let th = this
            let data = {
                uid:'KjI0O6zhu3OUGvzrSr75pLUtRaU2',
                //uid: 'sqLWNMuoxBezHLvfSJoSNrf2BTt1',
                properties: {
                    role: null,
                    subrole: null,
                    code: 'COA-1002',
                    activeRole: 'company-admin',
                    activeSubrole: null,
                    roles: [
                            {
                                slug: 'company-admin',
                                label: 'Company admin'
                            },
                            // {
                            //     slug: 'driver',
                            //     label: 'Driver',
                            //     subrole: {
                            //         slug:'owner-operator',
                            //         label: 'Owner operator' 
                            //     } 
                            // },
                            // {
                            //     slug: 'driver',
                            //     label: 'Driver',
                            //     subrole: {
                            //         slug:'company',
                            //         label: 'Company' 
                            //     } 
                            // }
                        ]
                }
            }
            return new Promise((resolve, reject) => {
                axios.post('update-user-claims',data).then(response =>{
                    console.log(response);
                    
                    resolve(response)
                    //th.$router.push({path: '/users/drivers'})
                }).catch(e => {
                    console.log(e);
                    
                    reject(e)
                    
                }) 
            })
        },
        getUserData() {
            console.log(this.user);

        },
        
    },
};
</script>
  